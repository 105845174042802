import React from "react";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import Audio from "../components/audio";
import Carousel from "../components/carousel";
import ExternalLink from "../components/externalLink";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div style={{maxWidth: `200px`, minWidth: '200px', cursor: 'pointer', float: 'right'}} title="Click Me!">
        <Audio soundName="snort">
          <Image index={2} />
        </Audio>
      </div>
      <h1>Welcome to Snorph!</h1>
      <p>
      Snorph is our brand of Pacific Northwest inspired designs.<br/>
      So grab an IPA and get a shirt that will help you get into the great PNW spirit!
      </p>
      <p>
      Swim (or waddle) on over to <ExternalLink href="https://teespring.com/stores/snorph-brand" title="our store" className="pageLink"/> now, to make it happen!
      </p>
      <div>
        <h2>Our products</h2>
        <Carousel />
      </div>
    </Layout>
  )
}

export default IndexPage;
