import React from 'react';

const externalLink = ({href, title, className = 'pageLink', children = undefined}) => 
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    title={title}
    className={className}
  >
    {children ? children : title}
  </a>;

export default externalLink;
