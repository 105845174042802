const products = [
  {
    title: 'Pacific NorthWet (Women)',
    link: 'https://teespring.com/pacific-northwet-w-vneck?tsmac=store&tsmic=snorph-brand&pid=391&cid=6596',
    image: '/products/1.jpg'
  },
  {
    title: 'Pacific NorthWet (Men)',
    link: 'https://teespring.com/pacific-northwet-m-tee?tsmac=store&tsmic=snorph-brand&pid=2&cid=576',
    image: '/products/2.jpg'
  },
  {
    title: 'Pacific NorthWet (Silver Round)',
    link: '/silver',
    image: '/products/silver.jpg'
  },
  {
    title: 'Meh (Men)',
    link: 'https://teespring.com/meh-m-tee?tsmac=store&tsmic=snorph-brand&pid=2&cid=576',
    image: '/products/3.jpg'
  },
  // {
  //   title: 'Inject Disinfectant, Kill Corona (Men)',
  //   link: 'https://teespring.com/inject-disinfect-kill-corona?tsmac=store&tsmic=snorph-brand&pid=87&cid=2326',
  //   image: '/products/4.jpg'
  // },
  // {
  //   title: 'Inject Disinfectant, Kill Corona Sticker',
  //   link: 'https://teespring.com/inject-disinfect-kill-corona?tsmac=store&tsmic=snorph-brand&pid=794&cid=103544',
  //   image: '/products/5.jpg'
  // },
  // {
  //   title: 'Inject Disinfectant, Kill Corona Tote',
  //   link: 'https://teespring.com/inject-disinfect-kill-corona?tsmac=store&tsmic=snorph-brand&pid=933&cid=103893',
  //   image: '/products/6.jpg'
  // }
];

export default products;
